AmCharts.themes.patterns = {
    themeName: "patterns",
    AmChart: {color: "#9a9a9a", backgroundColor: "#FFFFFF"},
    AmCoordinateChart: {
        colors: ["#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a"],
        patterns: [{
            "url": "patterns/black/pattern1.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern2.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern3.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern4.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern5.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern6.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern7.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern8.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern9.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern10.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern11.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern12.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern13.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern14.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern15.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern16.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern17.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern18.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern19.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern20.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern21.png",
            "width": 4,
            "height": 4
        }]
    },
    AmStockChart: {colors: ["#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a"]},
    AmPieChart: {depth3D: 0, angle: 0, labelRadius: 10},
    AmSlicedChart: {
        outlineAlpha: 0.3,
        colors: ["#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a", "#9a9a9a"],
        outlineThickness: 1,
        outlineColor: "#9a9a9a",
        labelTickColor: "#9a9a9a",
        labelTickAlpha: 0.3,
        patterns: [{
            "url": "patterns/black/pattern1.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern2.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern3.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern4.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern5.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern6.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern7.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern8.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern9.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern10.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern11.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern12.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern13.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern14.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern15.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern16.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern17.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern18.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern19.png",
            "width": 4,
            "height": 4
        }, {"url": "patterns/black/pattern20.png", "width": 4, "height": 4}, {
            "url": "patterns/black/pattern21.png",
            "width": 4,
            "height": 4
        }]
    },
    AmRectangularChart: {zoomOutButtonColor: '#9a9a9a', zoomOutButtonRollOverAlpha: 0.15, zoomOutButtonImage: "lens"},
    AxisBase: {axisColor: "#9a9a9a", axisAlpha: 0.3, gridAlpha: 0.05, gridColor: "#9a9a9a"},
    ChartScrollbar: {
        backgroundColor: "#9a9a9a",
        backgroundAlpha: 0.13,
        graphFillAlpha: 0.4,
        selectedGraphFillAlpha: 0.7,
        graphLineAlpha: 0,
        selectedBackgroundColor: "#FFFFFF",
        selectedBackgroundAlpha: 0.9,
        gridAlpha: 0.15
    },
    ChartCursor: {cursorColor: "#9a9a9a", color: "#FFFFFF", cursorAlpha: 0.5},
    AmLegend: {color: "#9a9a9a", markerBorderAlpha: 0.1, markerSize: 20, switchColor: "#9a9a9a"},
    AmGraph: {lineAlpha: 0.4, fillAlphas: 0.5},
    AmAngularGauge: {faceAlpha: 0.5, facePattern: {"url": "patterns/black/pattern1.png", "width": 4, "height": 4}},
    GaugeArrow: {
        color: "#9a9a9a",
        alpha: 1,
        nailAlpha: 1,
        innerRadius: "0%",
        nailRadius: 15,
        startWidth: 15,
        borderAlpha: 1,
        radius: "70%",
        nailBorderAlpha: 1
    },
    GaugeAxis: {
        tickColor: "#9a9a9a",
        tickAlpha: 1,
        tickLength: 15,
        minorTickLength: 8,
        axisThickness: 1,
        axisColor: '#9a9a9a',
        axisAlpha: 1,
        bandAlpha: 1
    },
    TrendLine: {lineColor: "#c03246", lineAlpha: 0.8},
    AreasSettings: {
        alpha: 0.8,
        colorSolid: "#9a9a9a",
        unlistedAreasAlpha: 0.4,
        unlistedAreasColor: "#9a9a9a",
        outlineColor: "#FFFFFF",
        outlineAlpha: 0.5,
        outlineThickness: 0.5,
        rollOverColor: "#DDDEE1",
        rollOverOutlineColor: "#FFFFFF",
        selectedOutlineColor: "#FFFFFF",
        selectedColor: "#DDDEE1",
        unlistedAreasOutlineColor: "#FFFFFF",
        unlistedAreasOutlineAlpha: 0.5
    },
    LinesSettings: {color: "#9a9a9a", alpha: 0.8},
    ImagesSettings: {alpha: 0.8, labelColor: "#9a9a9a", color: "#9a9a9a", labelRollOverColor: "#DDDEE1"},
    ZoomControl: {buttonRollOverColor: "#DDDEE1", buttonFillColor: "#DDDEE1", buttonFillAlpha: 0.8},
    SmallMap: {
        mapColor: "#9a9a9a",
        rectangleColor: "#FFFFFF",
        backgroundColor: "#FFFFFF",
        backgroundAlpha: 0.7,
        borderThickness: 1,
        borderAlpha: 0.8
    },
    PeriodSelector: {color: "#9a9a9a"},
    PeriodButton: {
        color: "#9a9a9a",
        background: "transparent",
        opacity: 0.7,
        border: "1px solid rgba(0, 0, 0, .3)",
        MozBorderRadius: "5px",
        borderRadius: "5px",
        margin: "1px",
        outline: "none",
        boxSizing: "border-box"
    },
    PeriodButtonSelected: {
        color: "#9a9a9a",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        border: "1px solid rgba(0, 0, 0, .3)",
        MozBorderRadius: "5px",
        borderRadius: "5px",
        margin: "1px",
        outline: "none",
        opacity: 1,
        boxSizing: "border-box"
    },
    PeriodInputField: {
        color: "#9a9a9a",
        background: "transparent",
        border: "1px solid rgba(0, 0, 0, .3)",
        outline: "none"
    },
    DataSetSelector: {
        color: "#9a9a9a",
        selectedBackgroundColor: "rgba(0, 0, 0, .25)",
        rollOverBackgroundColor: "rgba(0, 0, 0, .15)"
    },
    DataSetCompareList: {
        color: "#9a9a9a",
        lineHeight: "100%",
        boxSizing: "initial",
        webkitBoxSizing: "initial",
        border: "1px solid rgba(0, 0, 0, .3)"
    },
    DataSetSelect: {border: "1px solid rgba(0, 0, 0, .3)", outline: "none"}
};
