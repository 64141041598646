var $ = require('jquery');

function initTabs() {
    $('.option').click(function() {
        $('.option').removeClass('active');
        $(this).addClass('active');
    });
}

$(document).ready(function () {
    if($('.page-traductor').length) {
        initTabs();
    }
});

