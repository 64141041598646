/*******************************************************************************
 *******************************************************************************
 * Selectbox
 * @author    Perrine Boissières <perrine.boissieres@awstudio.fr>
 * @version   1.0.0
 * @requires  jQuery
 *******************************************************************************
 *******************************************************************************/
(function (factory) {
 'use strict';

  if (typeof define === 'function' && define.amd) {

    define(['jquery'], factory);

  } else if (typeof module === "object" && typeof module.exports === "object") {

    module.exports = factory(require('jquery'));

  } else {

    factory(jQuery);
  }

}(function($) {
    'use strict';

    var isIE8           = !!!document.addEventListener,
        globalCounter   = 0,      // increments when defining a new selectbox, serves as an ID
        localCounter    = 0,      // increments for each option of a selectbox
        typingTimeout   = null,   // stores the timeout for typing search in the selectbox
        typingResult    = "",     // stores the letters typed
        keys            = {       // special keys
            SPACEBAR        : 32,
            ENTER           : 13,
            LEFT_ARROW      : 37,
            UP_ARROW        : 38,
            RIGHT_ARROW     : 39,
            DOWN_ARROW      : 40,
            TAB             : 9,
            ESC             : 27,
            ALT             : 18
        },
        namespace       = '.selectbox',
        events          = {
            focus           : 'focus' + namespace,
            blur            : 'blur' + namespace,
            click           : 'click' + namespace,
            keydown         : 'keydown' + namespace,
            keyup           : 'keyup' + namespace,
            keypress        : 'keypress' + namespace,
            selectchange    : 'selectchange' + namespace,
            mousedown       : 'mousedown' + namespace,
            mouseover       : 'mouseover' + namespace,
            mouseout        : 'mouseout' + namespace,
            scroll          : 'scroll' + namespace,
            resize          : 'resize' + namespace
        };


    /***************************************************************************
     ***************************************************************************
     * Executes the requested method of the Selectbox plugin
     * @param   {string|object}     options       a method's name or a default options object
     * @param   {type}              parameters    an options object to pass to a method
     * @param   {type}              optionValue   the option's value to change
     * @return  {jQueryCollection}
     ***************************************************************************
     ***************************************************************************/
    $.fn.selectbox = function(options, parameters, optionValue) {

      var toReturn = this;

      this.each(function() {

          var $this = $(this),
              data = $(this).data('selectbox');


          if(!data) {

            $this.data('selectbox', (data = new Selectbox(this, options)));
          }
          if(typeof options === 'string' && options !== 'option') {

            toReturn = data[options](parameters);
          }
          if(options === 'option') {

            toReturn = data[options](parameters, optionValue);
          }
      });

      return toReturn; // chainable method
    };


    // Default settings
    $.fn.selectbox.settings = {
        html              : false,
        desktopAnimation  : 'toggle',
        mobileAnimation   : 'mobile',
        width             : 'min-width', // min-width|width|manual
        containerClass    : 'selectbox',
        buttonClass       : 'selectbox-button',
        listBoxClass      : 'selectbox-list-box',
        listClass         : 'selectbox-list',
        listItemClass     : 'selectbox-list-item',
        groupLabelClass   : 'selectbox-group-label',
        groupItemClass    : 'selectbox-group-item',
        dividerClass      : 'selectbox-list-divider',
        onParseOption     : null,
        optionsTitleLabel : null,
        optionsTitleClass : "selectbox-items-title",
        cancelBtnLabel    : "Cancel",
        cancelBtnClass    : "selectbox-cancel",
        mobileBreakpoint  : 768,
        isResponsive      : true
    };

    // Default closing/opening animations
    $.fn.selectbox.animations = {

      /*************************************************************************
       *************************************************************************
       * A simple toggle effect
       * @param   {jQueryCollection}  $selectbox
       * @param   {Selectbox}         api
       * @param   {boolean}           show
       * @return  {undefined}
       *************************************************************************
       *************************************************************************/
      toggle : function($selectbox, api, show) {

        if(show) {

          // We calculate the position of the selectbox
          var margin  = (api.$listBox.css('marginTop') !== 'auto')    ?
                            parseInt(api.$listBox.css('marginTop'))   :
                            0,
              top     = $selectbox.offset().top + $selectbox.outerHeight() + margin,
              left    = $selectbox.offset().left,
              width   = $selectbox.outerWidth();

          api.$listBox
              .show()
              .css({
                  'z-index'   : 3000,
                  'position'  : 'absolute',
                  'min-width' : width + 'px'
              });

          // If selectbox is too low and going to be cut, we open it upwards
          if(top + api.$listBox.height() > window.innerHeight + window.scrollY) {

            api.$listBox.offset({
                left : left,
                top : top - api.$listBox.height() - $selectbox.outerHeight()
            }).addClass('top');

          } else {

            api.$listBox.offset({
                left : left,
                top : top
            }).removeClass('top');
          }

        }
        else {

          api.$listBox.hide();
        }
      },

      /*************************************************************************
       *************************************************************************
       * Reproduce the mobile presentation
       * @param   {jQueryCollection}  $selectbox
       * @param   {Selectbox}         api
       * @param   {boolean}           show
       * @return  {undefined}
       *************************************************************************
       *************************************************************************/
      mobile : function($selectbox, api, show) {

        if(show) {

          $('body').addClass('stop-flow');
          api.$layer.addClass('open');
          api.$listBox.show();

          var top = (window.innerHeight - api.$listBox.height()) / 2;

          api.$listBox.css('top', top+'px');
        }
        else {

          $('body').removeClass('stop-flow');
          api.$layer.removeClass('open');
          api.$listBox.hide();
        }

      }
    };





    /***************************************************************************
     ***************************************************************************
     * Creates a new selectbox from a select input
     * @param   {DOMElement}  element   Select DOMElement the plugin is applied to
     * @param   {object}      options   Custom settings
     * @return  {Selectbox}
     ***************************************************************************
     ***************************************************************************/
    var Selectbox = function(element, options) {

      /**
       * The original <select> element
       * @type jQueryCollection
       */
      this.$element = $(element);

      /**
       * The id applied to the options list
       * @type string
       */
      this.uniqid = 'selectbox' + globalCounter;
      globalCounter++;

      // get the data-attributes and extends the options
      options = $.extend({}, options, this.$element.data());

      /**
       * Settings for the current selectbox
       * @type object
       */
      this.settings = $.extend({}, $.fn.selectbox.settings, options);


      // We wrap the select and hide it
      var div = $('<div />').addClass(this.settings.containerClass);
      this.$element.wrap(div).hide();

      /**
       * The wrapper around the <select> element
       * @type jQueryCollection
       */
      this.$container = this.$element.parent();

      // We create the list container
      this.$listBox = $('<div />').addClass(this.settings.listBoxClass).attr('id', this.uniqid);
      this.$list = $('<ul />').addClass(this.settings.listClass);


      // Other vars
      this.$button = $('<span tabindex="0" />')
                      .addClass(this.settings.buttonClass)
                      .attr('aria-owns', this.uniqid)
                      .attr('role', 'listbox')
                      .appendTo(this.$container);
      this.$cancelBtn = null;

      if(!$('.selectbox-layer').length) {

          $('body').append('<div class="selectbox-layer" />');
      }

      this.$layer = $('.selectbox-layer');


      // Selects the right animation (between desktop and mobile)
      if(this.settings.isResponsive) {

          if($(window).width() <= this.settings.mobileBreakpoint) {

              this.animation = this.settings.mobileAnimation;
          }
          else {

              this.animation = this.settings.desktopAnimation;
          }

      } else {

          this.animation = this.settings.desktopAnimation;
      }

      // Retrieve the label if parameter is not specified
      if(this.settings.optionsTitleLabel === null) {

        this.settings.optionsTitleLabel = $('label[for="'+this.$element.attr('id')+'"]').text();
      }


      // Checks when value has changed
      this.hasChanged = false;

      // State-change when alt is down
      this.isAltDown = false;


      // Set-up options and bind events on the selectbox
      this.parseOptions();

      this.bindEvents();
    };





    /***************************************************************************
     ***************************************************************************
     * Parse options from a select input and create them in the selectbox
     * @return {undefined}
     ***************************************************************************
     ***************************************************************************/
    Selectbox.prototype.parseOptions = function() {

        var selectedOpt = null,
            width       = 0,
            $options    = this.$element.children(),
            that        = this,
            selectVal   = this.$element.val();

        this.$listBox.children().remove(); // we empty the list
        this.$list.children().remove();


        // We had the title of the option-list (for mobile display)
        this.$listBox.append('<div class="'+this.settings.optionsTitleClass+'">'+this.settings.optionsTitleLabel+'</div>');

        $options.each(function(index) {

            var tagName = $(this)[0].tagName;

            // option-groups
            if(tagName === 'OPTGROUP') {

                var label         = $(this).attr('data-original-label') || $(this).attr('label'),
                    isGrpDisabled = $(this).prop('disabled'),
                    $options      = $(this).children();

                // Put a divider between two optgroups
                if(index > 0) $('<li class="'+that.settings.dividerClass+'" />').appendTo(that.$list);

                // Add the group label to the list
                $('<li class="'+that.settings.groupLabelClass+'">'+label+'</li>').appendTo(that.$list);

                $options.each(function(index) {

                    var $opt        = $('<li />').addClass(that.settings.groupItemClass).appendTo(that.$list),
                        isDefault   = ($(this)[0].hasAttribute('data-default')),
                        isDisabled  = isGrpDisabled || $(this).prop('disabled'),
                        label       = (typeof that.settings.onParseOption === "function") ?
                                      that.settings.parseOption($(this)) :
                                      $(this).attr('data-original-label') || $(this).text(),
                        isSelected  = $(this).prop('selected'),
                        now         = +new Date(),
                        uniqId      = $(this).attr('data-uniqid') || that.uniqid + '-' +localCounter;

                    $(this).attr('data-uniqid', uniqId);

                    // We use html() or text() jQuery methods to fill the option labels
                    if(that.settings.html) $opt.html(label);
                    else                   $opt.text(label);

                    if(isDefault || isSelected) {

                        if(that.settings.html) that.$button.html(label);
                        else                   that.$button.text(label);
                    }

                    if(isDisabled) $opt.addClass('state-disabled').attr('aria-disabled', true);

                    // Calculates the width if width option is not set to manual
                    if(that.settings.width === "min-width" || that.settings.width === "width") {

                        width = (getWidthOfOption($opt, that) > width) ?
                                getWidthOfOption($opt, that) :
                                width;
                    }


                    // If the option is marked up as "default", we remove it from
                    // the list (we also disable it)
                    if(isDefault) {

                        $opt.remove();
                        $(this).prop('disabled', true);
                    }

                    // If the option is marked up as selected, we store the id
                    if(isSelected || $(this).prop('value') === selectVal) selectedOpt = uniqId;

                    // Adds the uniqId markup to tie options
                    $opt.attr('id', uniqId)
                        .attr('role', 'option')
                        .attr('aria-selected', false);

                    $(this).attr('data-uniqid', uniqId);

                    localCounter++;
                });
            }
            else {

                var $opt        = $('<li />').addClass(that.settings.listItemClass).appendTo(that.$list),
                    isDefault   = ($(this)[0].hasAttribute('data-default')),
                    isDisabled  = $(this).prop('disabled'),
                    label       = (typeof that.settings.onParseOption === "function") ?
                                  that.settings.parseOption($(this)) :
                                  $(this).attr('data-original-label') || $(this).text(),
                    isSelected  = $(this).prop('selected'),
                    now         = +new Date(),
                    uniqId      = $(this).attr('data-uniqid') || that.uniqid + '-' +localCounter;

                $(this).attr('data-uniqid', uniqId);

                // We use html() or text() jQuery methods to fill the option labels
                if(that.settings.html) $opt.html(label);
                else                   $opt.text(label);

                if(isDefault || isSelected) {

                    if(that.settings.html) that.$button.html(label);
                    else                   that.$button.text(label);
                }

                if(isDisabled) $opt.addClass('state-disabled').attr('aria-disabled', true);

                // Calculates the width if width option is not set to manual
                if(that.settings.width === "min-width" || that.settings.width === "width") {

                    width = (getWidthOfOption($opt, that) > width) ?
                            getWidthOfOption($opt, that) :
                            width;
                }


                // If the option is marked up as "default", we remove it from
                // the list (we also disable it)
                if(isDefault) {

                    $opt.remove();
                    $(this).prop('disabled', true);
                }

                // If the option is marked up as selected, we store the id
                if(isSelected || $(this).prop('value') === selectVal) selectedOpt = uniqId;

                // Adds the uniqId markup to tie options
                $opt.attr('id', uniqId)
                    .attr('role', 'option')
                    .attr('aria-selected', false);

                $(this).attr('data-uniqid', uniqId);
            }

            localCounter++;
        });

        if(!this.$layer.children('#'+this.uniqid).find('ul').length) {

            this.$list.appendTo(this.$listBox);
            this.$listBox.appendTo(this.$layer);
        }

        // We add the close button of the option-list (for mobile display)
        this.$listBox.append('<div class="'+this.settings.cancelBtnClass+'"><button type="button">'+this.settings.cancelBtnLabel+'</button></div>');
        this.$cancelBtn = $('.'+this.settings.cancelBtnClass+' button', this.$listBox);

        // We add the class selected to the selected option
        if(selectedOpt) $('#'+selectedOpt).addClass('state-selected').attr('aria-selected', true);

        // If the select is disabled, we add the class disabled to the selectbox
        if(that.$element.prop('disabled')) that.$container.addClass('state-disabled');

        this.$button.attr('aria-activedescendant', selectedOpt);

        // Handles the auto-width if required by settings
        if(that.settings.width === "min-width") {

            that.$container.css('min-width', width+'px');
        }
        else if(that.settings.width === "width") {

            that.$container.css('width', width+'px');
        }


        localCounter = 0;
    };





    /***************************************************************************
     ***************************************************************************
     * Bind the events on the selectbox
     * @returns {undefined}
     ***************************************************************************
     ***************************************************************************/
    Selectbox.prototype.bindEvents = function() {

        var that = this;

        // Events callbacks

        this.$button.on(events.focus, $.proxy(function() {

            // If disabled, we blur immediatly
            if(this.$element.prop('disabled')) return this.$button.trigger(events.blur);

            this.$container.addClass('state-focus');

        }, this)).on(events.blur, $.proxy(function() {

            if($(document.activeElement).hasClass(this.settings.listBoxClass)) {

              this.$button.focus();
              return;
            }

            this.$container.removeClass('state-focus');

            if(this.hasChanged) {

                this.hasChanged = false;
                this.$element.trigger('change');
            }

            if(isIE8) {

                setTimeout($.proxy(this.close, this), 300);
            }
            else this.close();

        }, this)).on(events.keydown, $.proxy(function(e) {

            // Only executes callback on arrows
            if(e.keyCode === keys.LEFT_ARROW || e.keyCode === keys.UP_ARROW || e.keyCode === keys.RIGHT_ARROW || e.keyCode === keys.DOWN_ARROW) {

                // prevents default behaviors like window scrolling with arrows
                e.preventDefault();
                e.stopPropagation();


                var $selectedOpt = this.$list.children('.state-selected');

                if(this.isAltDown)
                  this.open();

                var $newOpt = null;

                // If there is a selected option
                if($selectedOpt.length) {

                    $newOpt = (e.keyCode === keys.LEFT_ARROW || e.keyCode === keys.UP_ARROW) ?
                              getNotDisabledOption($selectedOpt, false, this.settings) :
                              getNotDisabledOption($selectedOpt, true, this.settings);

                } else {

                    var availableLis = '.' + this.settings.listItemClass + ', .' + this.settings.groupItemClass;

                    $newOpt = (e.keyCode === keys.RIGHT_ARROW || e.keyCode === keys.DOWN_ARROW) ?
                              that.$list.children(availableLis).not('.state-disabled').eq(0) :
                              "";
                }

                if(!!$newOpt && $newOpt.length) {

                    var val = $('option[data-uniqid="'+$newOpt.attr('id')+'"]').attr('value');
                    this.$element.val(val);

                    this.$button.trigger('selectchange', [true]);
                }

            }
            else if(e.keyCode === keys.ALT) this.isAltDown = true;

        }, this)).on(events.keyup, $.proxy(function(e) {

          if(e.keyCode === keys.ALT) this.isAltDown = false;

        }, this)).on(events.keypress, $.proxy(function(e) {

            var excludedKeys = [keys.ENTER, keys.SPACEBAR, keys.TAB, keys.LEFT_ARROW, keys.UP_ARROW, keys.RIGHT_ARROW, keys.DOWN_ARROW];

            if(e.keyCode === keys.ESC) {

              this.close();

              return false;
            }
            // We're hitting letters and numbers, not space, enter, arrows
            // or tab key
            if(excludedKeys.indexOf(e.keyCode) === -1) {

                clearTimeout(typingTimeout);

                var char = String.fromCharCode(e.charCode);

                typingResult = typingResult+char;


                typingTimeout = setTimeout(function() {

                    typingResult = "";
                }, 1500);


                // Now we check options
                var pattern = new RegExp("^"+typingResult, "i"),
                    $options = this.$list.find('li:not(.state-disabled)'),
                    that = this;

                $options.each(function() {

                    var text = $.trim($(this).text());

                    if(pattern.test(text)) {

                        var val = $('option[data-uniqid="'+$(this).attr('id')+'"]').attr('value');
                        that.$element.val(val);

                        that.$button.trigger('selectchange', [true]);

                        return false;
                    }
                });
            }

        }, this)).on(events.selectchange, $.proxy(function(event, isTriggeredByKeyboard) {

            var uniqId = $('option:selected', this.$element).attr('data-uniqid'),
                selected = $('#'+uniqId),
                label = (this.settings.html) ? selected.html() : selected.text();

            // On n'exécute la suite que si la nouvelle option est différente de l'ancienne
            if(uniqId === this.$list.find('.state-selected').attr('id')) return;

            if(!selected.length) label = (this.settings.html) ? $('[data-default]', this).html() : $('[data-default]', this).text();

            this.$list.find('.state-selected').removeClass('state-selected');
            selected.addClass('state-selected');

            if(this.settings.html) {

                this.$button.html(label);
            }
            else {

                this.$button.text(label);
            }

            // ARIA
            this.$button.attr('aria-activedescendant', uniqId);
            this.$list.children().attr('aria-selected', false);
            selected.attr('aria-selected', true);

            // In case the value change is triggered by keyboard inputs
            if(isTriggeredByKeyboard) {

                // We scroll the list-box so we can see the selected option
                var height = this.$listBox.outerHeight(),
                    top = selected.index() * selected.outerHeight();

                this.$list.closest('.selectbox-list-box').scrollTop(top - height / 2 + selected.outerHeight());
            }

            //this.$element.trigger('change');
            this.hasChanged = true;

        }, this));

        // Prevent the loss of focus when we click on the selectbox (Webkit,
        // Gecko and IE9+ only)
        this.$listBox.on(events.mousedown, function(e) {

            e.preventDefault();
        });

        // Closes the selectbox when the window is scrolled
        $(window).on(events.scroll, $.proxy(function() {

            if(this.$element.hasClass('state-open')) this.close();

        }, this));

        if(this.settings.isResponsive) {

            $(window).on(events.resize, $.proxy(function() {

                // We close the selectbox before everything
                if(this.$element.hasClass('state-open')) this.close();

                var width = $(window).width();

                // We also check the doc width and switch to mobile animation
                if(width <= this.settings.mobileBreakpoint && this.animation !== this.settings.mobileAnimation) {

                    this.animation = this.settings.mobileAnimation;
                    this.refresh();
                }
                else if(width > this.settings.mobileBreakpoint && this.animation !== this.settings.desktopAnimation) {

                    this.animation = this.settings.desktopAnimation;
                    this.refresh();
                }

            }, this));
        }

        // Handles the click on the closed selectbox
        this.$button.on(events.click, $.proxy(function() {

            var show = (!this.$element.hasClass('state-open'));

            if(show) {

                this.$button.trigger(events.focus);

                this.open();
            }
            else {

                this.close();
            }


        }, this));

        this.$cancelBtn.on(events.click, $.proxy(function() {

            this.close();

        }, this));

        // Handles the click on the options
        var li = "li." + this.settings.listItemClass + ", li." + this.settings.groupItemClass;
        this.$listBox.on(events.click, li, {api: this},function(e) {

            var api = e.data.api;

            if($(this).hasClass('state-selected')) {

                api.close();
                return false;
            }
            if($(this).hasClass('state-disabled')) {

                return false;
            }

            var val = $('[data-uniqid="'+$(this).attr('id')+'"]').val();

            $('option:selected', api.$element).prop('selected', false);
            $(this).prop('selected', true);

            api.$element.val(val);

            api.$button.trigger('selectchange');

            if(api.hasChanged) {

                api.hasChanged = false;
                api.$element.trigger('change');
            }

            api.close();
        });

        // Selects the option when hitting "enter" whith the mouse over it
        this.$listBox.on(events.mouseover, li, function() {

            var li = $(this);

            $(window).on(events.keyup, function(e) {

                if(e.keyCode === keys.ENTER || e.keyCode === keys.SPACEBAR) {

                    li.trigger('click');
                }
            });
        });
        this.$listBox.on(events.mouseout, li, function() {

            $(window).off(events.keyup);
        });


        // Click on labels
        $('body').on(events.click, 'label[for='+this.$element.attr('id')+']', $.proxy(function(e) {

          e.preventDefault();
          this.$button.click();
        }, this));
    };





    /***************************************************************************
     ***************************************************************************
     * Destroys the selectbox and shows the standard select input
     * @return {undefined}
     ***************************************************************************
     ***************************************************************************/
    Selectbox.prototype.destroy = function() {

        this.$element.removeData('selectbox').off(namespace).unwrap().show();
        this.$listBox.remove();
        this.$button.remove();
    };





    /***************************************************************************
     ***************************************************************************
     * Calls the closing animation function
     * @return {undefined}
     ***************************************************************************
     ***************************************************************************/
    Selectbox.prototype.close = function() {

        if(!this.$element.hasClass('state-open')) return;

        if(typeof this.animation === 'string') {

            $.fn.selectbox.animations[this.animation](this.$container, this, false);
        }
        else {

            this.animation(this.$container, this, false);
        }

        this.$element.removeClass('state-open');
    };





    /***************************************************************************
     ***************************************************************************
     * Calls the opening animation function
     * @return {undefined}
     ***************************************************************************
     ***************************************************************************/
    Selectbox.prototype.open = function() {

        if(this.$element.hasClass('state-open') || this.$container.hasClass('state-disabled')) return;

        if(typeof this.animation === 'string') {

            $.fn.selectbox.animations[this.animation](this.$container, this, true);
        }
        else {

            this.animation(this.$container, this, true);
        }

        this.$element.addClass('state-open');
    };





    /***************************************************************************
     ***************************************************************************
     * Changes a setting
     * @param   {string}    optionName
     * @param   {mixed}     optionValue
     * @return  {undefined}
     ***************************************************************************
     ***************************************************************************/
    Selectbox.prototype.option = function(optionName, optionValue) {

        this.settings[optionName] = optionValue;

        this.$element.data('selectbox', this);

        this.refresh();
    };





    /***************************************************************************
     ***************************************************************************
     * Changes all settings
     * @param   {object}    options
     * @return  {undefined}
     ***************************************************************************
     ***************************************************************************/
    Selectbox.prototype.options = function(options) {

        this.settings = $.extend({}, this.settings, options);

        this.$element.data('selectbox', this);

        this.refresh();
    };





    /***************************************************************************
     ***************************************************************************
     * Rebuilds the selectbox
     * @return {unresolved}
     ***************************************************************************
     ***************************************************************************/
    Selectbox.prototype.refresh = function() {

        this.$container.attr('class', this.settings.containerClass);
        this.$button.attr('class', this.settings.buttonClass);
        this.$list.attr('class', this.settings.listClass);
        this.$listBox.attr('class', this.settings.listBoxClass);

        this.parseOptions();
    };





    /***************************************************************************
     ***************************************************************************
     * Enables the selectbox
     * @return {unresolved}
     ***************************************************************************
     ***************************************************************************/
    Selectbox.prototype.enable = function() {

        this.$container.removeClass('state-disabled');
        this.$element.prop('disabled', false);
        this.$button.attr({ tabindex : 0, 'aria-disabled': false });
    };





    /***************************************************************************
     ***************************************************************************
     * Disables the selectbox
     * @return {unresolved}
     ***************************************************************************
     ***************************************************************************/
    Selectbox.prototype.disable = function() {

        this.$container.addClass('state-disabled');
        this.$element.prop('disabled', true);
        this.$button.attr({ tabindex : -1, 'aria-disabled': true });
    };





    /***************************************************************************
     ***************************************************************************
     * Checks if the selectbox is disabled
     * @return {boolean}
     ***************************************************************************
     ***************************************************************************/
    Selectbox.prototype.isDisabled = function() {

        return this.$element.prop('disabled');
    };





    /***************************************************************************
     ***************************************************************************
     * Sets the value of the selectbox
     * @returns {unresolved}
     ***************************************************************************
     ***************************************************************************/
    Selectbox.prototype.setValue = function(value) {

        this.$element.val(value);

        this.$button.trigger(events.selectchange);
    };





    ////////////////////////////////////////////////////////////////////////////
    // UTILITARIES /////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////

    var getWidthOfOption = function($option, api) {

        var $test = $('<span class="' + api.settings.buttonClass + '" />').appendTo(api.$container);

        $test.append($option.html());

        //on IE8 $test.outerWidth() <=> 0
        var width = $test.outerWidth();

        $test.remove();

        return width;
    };

    var getHeightOfOption = function($option, api) {

        var $test = $('<span class="' + api.settings.buttonClass + '" />').appendTo(api.$container);

        $test.append($option.html());

        var height = $test.outerHeight();

        $test.remove();

        return height;
    };

    var getNotDisabledOption = function($option, isNext, settings) {

      var $next = null;

        if(isNext) {

            $next = $option.next();
        }
        else {

            $next = $option.prev();
        }

        if($next.length && !$next.hasClass('disabled') && ($next.hasClass(settings.listItemClass) || $next.hasClass(settings.groupItemClass))) return $next;

        if($next.length) return getNotDisabledOption($next, isNext, settings);

        return false;
    };





})); // --end of line
