var $ = window.jQuery = window.$ = require('jquery');
var Masonry = require('masonry-layout');
var msnry, elementsFiltered = [];


function updateFilters(pEvt) {
    var filters = {};
    var $checkbox = $('[data-filter-input] input:checked');
    var filtered = false;

    $checkbox.each(function(i, el) {
        filtered = true;
        var $el = $(el);
        var $parent = $el.closest('[data-filter-input]');
        var category = $parent.data('filter-input');
        filters[category] = filters[category] || [];
        filters[category].push( $el.val() );
    });

    for (var i in elementsFiltered ) {
        var element = elementsFiltered[i],
        $el = element.element;
        if (!filtered) $el.show();
        else {
            var show = false, filter = element.filters;
            for (var f in filters) {
                if ($(filters[f]).filter(filter[f]).length === 0) {
                    show = false;
                    break;
                }
                if ($(filters[f]).filter(filter[f]).length > 0) show = true;
            }
            $el.toggle(show);
        }
    }
    msnry.layout();
}


function initFilters() {
    var $checkbox = $('[data-filter-input] input');
    $('[data-filter]').each(function(i, el) {
        var $el = $(el);
        var themes = $el.data('filter').toString(),
            departements = $el.data('filter-departements').toString(),
            batiments = $el.data('filter-type-building').toString();

        elementsFiltered.push({
            element: $el,
            filters: {
                themes: themes && themes !== '' ? themes.split('|') : null,
                departements: departements && departements !== '' ? departements.split('|') : null,
                batiments: batiments && batiments !== '' ? batiments.split('|') : null
            }
        })

    });
    $checkbox.on('change', updateFilters);
}

$(document).ready(function () {
    if($('.page-article').length) {
        // MASONRY /////////////////////////////////////////////////////////////////
        if($('#MasonryContainer').length) {
            msnry = new Masonry( '#MasonryContainer', {
                itemSelector: '.thematic',
                columnWidth: 50,
            });
            initFilters();
        }
    };
});
