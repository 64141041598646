var $ = require('jquery');

var InputUpdate = function($element) {

  var updated = false,
      timer;

  $element.on({
    'keyup': function(e, preventAction) {

      if (preventAction === true) return;

      updated = true;

      clearTimeout(timer);

      $element = $(this);

      timer = setTimeout(function() {

        updated = false;

        $element.trigger('update');
      }, 500);
    },
    'blur': function() {

      if (updated) {

        clearTimeout(timer);

        updated = false;

        $(this).trigger('update');
      }
    }
  });
};

$.fn.inputUpdate = function() {

  $(this).each(function() {

    InputUpdate($(this));
  });

  return $(this);
};
