var $ = require('jquery');

function init() {
    $('.third-level-trigger').on('click', function (e) {
        if (window.outerWidth > 767){
            e.stopPropagation();
            e.preventDefault();

            $(this).toggleClass('active');
            if ($(this).hasClass('active')){
                show();
            }else{
                hide();
            }
        }
    });

    $('.second-level-trigger, .third-level-trigger').on('click', function (e) {
        if (window.outerWidth < 768){
            e.stopPropagation();
            e.preventDefault();

            $(e.currentTarget).toggleClass('active');
        }
    });

    $('.main-header .back').on('click', function(e){
        $(e.currentTarget).closest('ul').prev().toggleClass('active');
    });
}

$(document).ready(init);

function show(){
    $('.third-level').show();
}
function hide(){
    $('.third-level').hide();
}