var pushTimeout = null;
$(function() {
  $('[data-push-component]').on('mouseenter', function (pEvt) {
    clearTimeout(pushTimeout);
    $(pEvt.currentTarget).addClass('is-active');
  });

  $('[data-push-component]').on('mouseleave', function (pEvt) {
    var $pushComponent = $(pEvt.currentTarget);
    pushTimeout = setTimeout(function () {
      $pushComponent.removeClass('is-active');
    }, 1500);
  });
})
