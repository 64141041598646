var $ = require('jquery');
require('amcharts3');
var activeOptions = 'integrators';
var map;

function initMap(color) {
    //map
    require('./patterns.js');


    map = AmCharts.makeChart( "mapdiv", {
        "type": "map",
        "color": "red",
        "theme": "patterns",
        "colorSteps": 10,
        "dragMap": false,
        "panEventsEnabled": false,
        "dataProvider": {
            "map": "franceDepartmentsLow",
            "getAreasFromMap": true
        },
        "areasSettings": {
            "autoZoom": false,
            "selectable": true,
            "color": color
        },
        "zoomControl": {
            "zoomControlEnabled": false,
            "homeButtonEnabled": false,
            "panControlEnabled": false
        }
    });


    map.addListener("clickMapObject", function (event) {
        //console.log('Clicked ID: ' + event.mapObject.id + ' (' + event.mapObject.title + ')');

        var id = event.mapObject.id.slice(3);
        var $target = $('#' + activeOptions + id);

        hideAllPopins();
        if($target.length == 0){
            $('#no-contact').show();
        }else{
            $target.show();
        }
    })
}

function hideAllPopins() {
    var $popins = $('.popin');
    $popins.hide();
}

function initTabs() {
    var $tabs = $('[id^="tab-"]');

    $tabs.hide();
    $tabs.first().show();

    $('.option').click(function() {
        var target = $(this).children('span, div').attr('data-target');
        window.location.hash = target;
        activeOptions = target.slice(5);

        $('.option').removeClass('active');
        $(this).addClass('active');

        $tabs.hide();
        $(target).show();
    });
}

function initSelect() {
    $('#findOption').change(function () {
        activeOptions = $('#findOption option:selected').val();
    });
}

function mobileFormButton() {
    var id = $('#findDepartment option:selected').val();
    var target = $('#' + activeOptions + id);

    hideAllPopins();
    $(target).show();

}

function setPhoneNumber(){
    var targetName = $('.contact-tab__options .option.active > div').attr('data-target');
    var $faxContainer = $('.contact-side .address .fax_number');
    switch(targetName) {
        case "#tab-prescribers":
            $phoneContainer.text("02 47 55 37 00");
            $faxContainer.text("Fax : +33 2 47 55 37 09");

            break;
        case "#tab-integrators":
            $phoneContainer.text("02 47 55 37 00");
            $faxContainer.text("Fax : +33 2 47 55 37 01");

            break;
        case "#tab-distributors":
            $phoneContainer.text("02 47 55 37 02");
            $faxContainer.text("Fax : +33 2 47 55 37 03");

            break;
        default:
            $phoneContainer.text("02 47 55 37 00");
            $faxContainer.text("Fax : +33 2 47 55 37 01");

    }
}

function setSideDirector(){
    var targetName = $('.contact-tab__options .option.active > div').attr('data-target');
    $sideContainer.show();
    $sideContainer.prev().show();
    switch(targetName) {
        case "#tab-prescribers":
            var img =  $('.lionel-gregoire').attr('src');
            //$sideContainer.hide().empty();
            //$sideContainer.prev().hide();
            $sideContainer.html("<b>Directeur prescription national : </b> <br> <div class='directeur-photo'> <img src='" + img + "'> </div><span>Marc BELLAIR </span> <br> <span>06 30 28 32 76 </span> <br> <span>mbellair@bluetek.fr </span>");
            break;
        case "#tab-integrators":
            var img =  $('.francis-georges').attr('src');
            $sideContainer.html("<b>Direction des ventes : </b> <div class='directeur-photo'> <img src='" + img + "'> </div><br> <span>Francis Georges </span> <br> <span>+337.70.26.77.78 </span> <br> <span>fgeorges@bluetek.fr </span>");;
            break;
        case "#tab-distributors":
            var img =  $('.georges-guerin').attr('src');
            $sideContainer.html("<b>Directeur réseau distribution  : </b> <br> <div class='directeur-photo'> <img src='" + img + "'> </div><span>Georges Guérin </span> <br> <span>06 07 29 15 44 </span> <br> <span>glguerin@bluetek.fr </span>");
            break;
        default:
            var img =  $('.francis-georges').attr('src');
            $sideContainer.html("<b>Direction des ventes : </b> <br><div class='directeur-photo'> <img src='" + img + "'> </div> <span>Francis Georges </span> <br> <span>+337.70.26.77.78 </span> <br> <span>fgeorges@bluetek.fr </span>");
    }
}

function updateHash() {
    var hash = document.location.hash;
    if (hash && (hash === '#tab-prescribers' || hash === '#tab-integrators' || hash === '#tab-distributors')) {
        $('[data-target="' + hash + '"]').trigger('click');
    }
}

$(document).ready(function () {
    if($('.page-contact').length) {
        if($('#mapdiv').length)
            initMap($('.option.active').data("color"));

        $('i.icon-close').click(function() { hideAllPopins() });
        $('#form_contact_submit').click(function () { mobileFormButton() });

        $phoneContainer = $('.contact-side .phone .phone-number');
        $sideContainer = $('.contact-side .address .directeur-side');

        $('.contact-tab__options .option').click(function(){
            setTimeout(function() {
                setPhoneNumber();
                setSideDirector();
            }, 300);
        });

        $('.option').click(function() {
            initMap($(this).data("color"));
        });

        $('.bloc-expert-col').click(function() {
            $(this).find('.expert-info').slideToggle();
            $(this).find('i').toggleClass('fa fa-chevron-down fa fa-chevron-up')
        });

        hideAllPopins();
        initTabs();
        initSelect();
        setPhoneNumber();

        updateHash();
        window.addEventListener('hashchange', updateHash, false);
    }
});
