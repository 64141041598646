var $ = require('jquery');
require('./input-update.js');

var $mainSearch, $searchField, $searchAssist, requestDefer, resultCount = 0, $searchDoc, searchValue;

function initSearch() {
    $mainSearch = $(document.getElementById('MainSearch'));
    $searchField = $mainSearch.find('input');
    $searchDoc = $(document.getElementById('DocSearchForm__keyword'));
    $searchAssist = $('.SearchAssist').hide();

    $('body').on('click.bluetek.search', '[data-action="toggle-search"]', toggleSearch);
    $searchField.inputUpdate().on('update', openAssist);

    $searchDoc.inputUpdate().on('update', openAssist);
    $('body').on('click.bluetekf.search', closeAssist);

    var firstDefer = $.Deferred();
    firstDefer.resolve();

    requestDefer = firstDefer.promise();
}

function toggleSearch(event) {

    event.preventDefault();

    $mainSearch.toggleClass('state--open');
    $(this).find('i').toggleClass('icon-search icon-close');
}

function autocomplete(el) {
    var url = (el.prop('id') === 'DocSearchForm__keyword')
      ? Routing.generate('app_product_autocomplete', {'doc-search': true})
      : Routing.generate('app_product_autocomplete');
    searchValue = el.val();
    return $.ajax({
        url: url,
        type: 'GET',
        data: "name=" + el.val()
    }).done(fillAutocomplete);
}

function putWeight(match) {
    return '<b>' + match + '</b>';
}

function fillAutocomplete(response) {

    resultCount = parseInt($(response).find('.searchTotalCount').html());
    $($searchAssist).html(response);

    var reg = new RegExp(searchValue, 'gi');

    $searchAssist.find('.js-autocomplete-list a').each(function () {

        var t = $(this).text().replace(reg, putWeight);
        $(this).html(t);
    });
}

function openAssist(event) {
    var $that = $(this);
    if ($(this).val().length < 2) return;

    var d = $.Deferred();

    requestDefer.then(autocomplete($that))
        .then(function () {
            $that.closest('form').find('.SearchAssist').show();
            d.resolve();
      });

    requestDefer = d.promise();
}

function closeAssist(event) {

    if ($(document.activeElement).closest('#SearchAssist').length) return;

    $searchAssist.hide();
}

$(document).ready(function () {
    initSearch();
    // $('.main-search-result').hide();
    //
    // $('.main-search-field input').change(function () {
    //     if($(this).val().length) {
    //         $('.main-search-result').show();
    //     } else {
    //         $('.main-search-result').hide();
    //     }
    // })
});


