// libraries
var $ = window.jQuery = window.$ = require('jquery');
require('bootstrap');
require('selectbox');

//modules
require('./front/menu.js');
require('./front/search.js');
require('./front/search-page.js');
require('./front/contact.js');
require('./front/traductor.js');
require('./front/article.js');
require('./front/push');
require('./front/jquery.bgswitcher.js');


/*////////////////////////////////////////////////////////////////////////////
 // CHECKBOXES
 ////////////////////////////////////////////////////////////////////////////*/
function initCheckboxes() {

    $('body').on('click.soprema.checkbox', '.checkbox input, .checkbox-inline input', toggleCheck);

    $('.checkbox input, .checkbox-inline input').each(toggleCheck);
}

function toggleCheck() {

    $this = $(this);

    if ($this.prop('checked')) {

        $this.closest('.checkbox, .checkbox-inline').addClass('state--checked');
    }
    else {

        $this.closest('.checkbox, .checkbox-inline').removeClass('state--checked');
    }
}
// TABS BUTTONS ////////////////////////////////////////////////////////////
$('.icon-btn[data-toggle="tab"]').on('show.bs.tab', function () {

    $(this).parent().children().removeClass('active');
    $(this).addClass('active');
});

// Submenus visibility ////////////////////////////////////////////////////
$(".dropdown-toggle, .third-level-trigger").on('click', function () {
    var $this = $(this);
    if (window.outerWidth > 991){
        setTimeout(function() {
            setSubmenuVisible($this);
        }, 1);
    }
});

function setSubmenuVisible(el){
    var $el = el.next('ul');
    var offset = $el.offset();
    var left = offset.left;
    var width = $el.width();
    var parentWidth = $el.closest('.container').width();
    var isVisible = (left + width <= parentWidth);

    if(!isVisible){
        $el.addClass('reverse');
    }else{
        $el.removeClass('reverse');
    }
}

function scrollTo($el){
    $('html,body').animate({
        scrollTop: $el.offset().top
    }, 300);
}

$(document).ready(function () {
    initCheckboxes();
    openFilters();

    // Avoid scroll when opening the mobile menu
    $('.burger-icon-container').on('click', function(e){
        e.preventDefault();
    });

    $('select.custom').selectbox({
        width: 'manual'
    });

    // init rowsHeight function to apply same height for the mega-rows
    var $megaRows = $('.cms-row:not(".no-resize")');
    var elementsToFind = '> div > div, > div > section, > div > a, > div > div > form';
    if (window.innerWidth > 767) {
        setTimeout(function() {
            rowsheight($megaRows);
        }, 1000);
    }

    // Toggle mobile search
    $('.main-header .search-icon').on('click', function () {
        var $mainSearch = $('.main-search');
        if(window.innerWidth < 768){
            $mainSearch.toggleClass('active');
        }
        if($mainSearch.hasClass('active') && window.innerWidth < 768){
            scrollTo($mainSearch);
        }else if(window.innerWidth > 767){
            scrollTo($mainSearch);
        }
    });

    // Trigger rowsHeight on resize
    var globalResizeTimer = null;
    $(window).resize(function () {
        if (globalResizeTimer != null) window.clearTimeout(globalResizeTimer);
        if (window.innerWidth > 767) {
            globalResizeTimer = window.setTimeout(function () {
                rowsheight($megaRows);
            }, 400);
        }
        else {
            $megaRows.find(elementsToFind).css('min-height', 'auto');
            $megaRows.find(elementsToFind).css('height', 'initial');
        }
    });

    // toggle archive product state
    $('.page-traductor .product-archive > .row .btn').on('click', function(){
        var $el = $(this);
        var $elToToggle = $el.closest('.col-md-6');
        var $rows = $('.page-traductor .search-results > .row > div');

        // If there is no reference
        if($elToToggle.find('.collapse').length == 0){
            return false;
        }

        $rows.not($elToToggle).removeClass('active');
        $rows.not($elToToggle).find('.collapse').removeClass('in');
        $elToToggle.toggleClass('active');

        // Check if the absolute collapse is outside the container
        setTimeout(checkOffset, 500);
    });
    $('.search-results .product .icon-close').on('click', function () {
        $(this).parents('.col-md-6.active').removeClass('active');
        setTimeout(checkOffset, 500);
    });

    if ($('#diapo-images').length > 0) {
        var imagesSlide = [];
        $('#diapo-images').find('img').each(function( index ) {
            imagesSlide.push($( this ).attr('src'));
        });

        $(".header-carousel").bgswitcher({
            images: imagesSlide,
            effect: "fade",
            interval: 5000
        });
    }
});

function checkOffset(){
    var containerBottomPosition = $('.search-results')[0].getBoundingClientRect().bottom;
    var containerPaddingBottom = parseInt($('.search-results').css("padding-bottom").replace("px", ""));
    if($('.search-results .active .collapse')[0] === undefined){
        $('.search-results').css('padding-bottom', 30);
    }else{
        var elementBottomPosition = $('.search-results .active .collapse')[0].getBoundingClientRect().bottom;
    }
    if(containerBottomPosition - containerPaddingBottom < elementBottomPosition){
        $('.search-results').css('padding-bottom', elementBottomPosition - containerBottomPosition + containerPaddingBottom);
    } else{
        $('.search-results').css('padding-bottom', 30);
    }
}

function rowsheight($megaRows) {
    $megaRows.each(function () {
        var $row = $(this);
        var $heightRow = $row.outerHeight();
        $row.find('> div').each(function (i, div) {
            if ($(div).outerHeight() != $heightRow) {
                var nbChildren = $(div).children().length;
                var colHeight = 0;
                if (nbChildren == 1) {
                    $(div).children().css('height', $(div).outerHeight());
                }
                else {
                    $(div).children().each(function (i, child) {
                        $(child).css('height', $(child).outerHeight());
                    });
                }
            }
        });
    });
    $megaRows.each(function () {
        var $row = $(this);
        var $heightRow = $row.outerHeight();
        var $widthRow = $row.outerWidth();
        $row.find('> div').each(function (i, div) {
            if ($(div).outerHeight() != $heightRow && $(div).outerWidth() != $widthRow) {
                var nbChildren = $(div).children().length;
                var colHeight = 0;
                if (nbChildren == 1) {
                    $(div).children().css('height', $heightRow);
                }
                else {
                    $(div).children().each(function (i, child) {
                        colHeight = $(child).outerHeight() + colHeight;
                    });
                    var diff = $heightRow - colHeight;
                    var heightPerColumn = diff / nbChildren;
                    $(div).children().each(function (i, child) {
                        $(child).css('height', $(child).outerHeight() + heightPerColumn);
                    });
                }
            }
        });
    });

// // IMG COVER
//     setTimeout(function () {
//         $('.linked-articles .row > div.image-container').each(function (i, div) {
//             var imgParentContainerHeight = $(div).closest('.linked-articles').outerHeight();
//             $(div).css('height', imgParentContainerHeight);
//         });
//
//     }, 600)


}

function openFilters(){
    $('#ProductFiltersTitle_0').click();
}
