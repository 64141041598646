var $ = require('jquery');
var timeLoop = require('time-loop');

var searchPage, scrollY = 0, y;

/**
 * @class SearchPage
 * Handles search page interactions and data fetching
 */
var SearchPage = function ($element, typePage) {
    this.categories = [];
    this.$el = $element;
    this.typePage = typePage;

    this.$filtersContainer = this.$el.find('#SelectedFilters ul');
    this.search = window.location.search.replace(/^\?/i, '');

    var self = this;
    this.$el.find('a.search-categories__item').each(function (index) {
        self.categories.push(new SearchCategory($(this), self));
        self.categories[index].hide();
        if ($(this).attr('href') === window.location.hash) {
            self.currentCategory = self.categories[index];
        }
    });

    if (!self.currentCategory) {
        self.currentCategory = self.categories[0];
    }
    self.currentCategory.show();


    this.$el.find('.searchFilters input[type="checkbox"]').on('click', function (e) {
        var txt = $(this).parent().text();

        if ($(this).prop('checked')) {
            self.currentCategory.addFilter(txt, this);
        }
        else {
            self.currentCategory.removeFilter(txt);
        }
    });

    this.$el.find('#SelectedFilters > button').on('click', function () {

        self.currentCategory.clearFilters();
    });

    this.$el.on('click', '#SelectedFilters li button', function () {

        var txt = $(this).parent().text();

        self.currentCategory.removeFilter(txt);
    });

    this.timerScroll = timeLoop.createEvent({
        type: 'interval',
        time: 1000 / 33
    }, this.checkVerticalScroll, this);
};
SearchPage.prototype.checkVerticalScroll = function () {

    y = scrollY + ($(window).height() * 2 / 3);

    if (y >= this.currentCategory.$target.offset().top + this.currentCategory.$target.height()) {

        this.timerScroll.pause();

        var self = this;
        this.currentCategory.currentPage++;
        this.currentCategory.fetchData()
            .then($.proxy(this.currentCategory.appendContent, this.currentCategory))
            .then(function () {
                self.timerScroll.unpause();
            });
    }
};
SearchPage.prototype.refreshFilters = function () {

    this.$filtersContainer.find('li').remove();

    this.$el.find('.searchFilters input[type="checkbox"]').each(function () {

        $(this).prop('checked', false).closest('.checkbox').removeClass('state--checked');
    });

    if (!this.currentCategory.filters.length) this.$filtersContainer.parent().hide();
    else {
        for (var i = 0; i < this.currentCategory.filters.length; i++) {

            $(this.currentCategory.filters[i].el).prop('checked', true).closest('.checkbox').addClass('state--checked');
            var txt = $(this.currentCategory.filters[i].el).parent().text();
            this.$filtersContainer.append('<li class="clearfix"><button type="button" class="btn btn-link pull-right"><i class="icon-close"></i></button>' + txt + '</li>');
            this.$filtersContainer.parent().show();
        }
    }
};


/**
 * @class SearchCategory
 * A category of data
 */
var SearchCategory = function ($element, page) {

    this.name = $element.attr('data-type');
    this.$el = $element;
    this.page = page;
    this.$target = this.page.$el.find('[data-category="' + this.name + '"]');
    this.$list = (page.typePage == "product") ? this.$target : this.$target.children('ul');

    this.currentPage = 0;
    this.limit = 30;
    this.filters = [];
    this.fullData = false;

    var self = this;

    this.$el.on('click', function (e) {
        e.preventDefault();
        for (var i = 0; i < self.page.categories.length; i++) {
            self.page.categories[i].hide();
        }
        self.show();
    });
};
SearchCategory.prototype.show = function () {

    this.$el.addClass('active');
    this.$target.show();
    this.page.currentCategory = this;
    this.page.refreshFilters();

    var type = this.$el.data('type');
    var $filters = $('.searchFilters').find('.topFilter');

    $('#ProductFilters').children('[data-filter-category="' + this.name + '"]').show();
};
SearchCategory.prototype.hide = function () {

    this.$el.removeClass('active');
    this.$target.hide();

    $('#ProductFilters').children('[data-filter-category="' + this.name + '"]').hide();
};
SearchCategory.prototype.fetchData = function () {

    if (this.fullData) {
        var d = $.Deferred();
        d.reject();
        this.page.timerScroll.unpause();

        return d.promise();
    }

    var route = (this.page.typePage == 'product') ? 'search_products' : 'search_products';

    var url = Routing.generate(route, {
        type: this.name,
        offset: (this.currentPage * this.limit)
    });

    this.$target.addClass('state--searching');
    var data = $(this.page.$el.find('form')).serialize();
    if (this.page.search) {
        data += '&' + this.page.search
    }

    var isPrescriptor = (this.page.$el.data('type') == "prescriptor") ? 1 : 0;
    if (this.page.typePage == 'product' && $('#ProductFilters').data('range')) {
        data += '&' + $.param({'product_ranges.id[]': $('#ProductFilters').data('range'),'prescriptor' : isPrescriptor});
    }

    var self = this;
    return $.ajax({
        url: url,
        type: 'GET',
        data: data
    })
        .then(function (response) {
            self.$target.removeClass('state--searching');

            if (response.count < self.limit) {
                self.fullData = true;
            }

            return response;
        });
};
SearchCategory.prototype.replaceContent = function (response) {
    // this.$list.html(response.view);
    // if ($('#ProductPage').length > 0) {
    //     this.page.$el.find('.badge').html(response.count);
    // }
    // var oldCount = this.$el.find('.badge').html();
    // var diff = oldCount - response.count;
    // this.$el.find('.badge').html(response.count);
    // var $totalCountContainer = $('.search-results__title span.pull-right');
    // if($totalCountContainer.html()){
    //   var totalCount = $totalCountContainer.html().split(' ')[0];
    //   $totalCountContainer.html($totalCountContainer.html().replace(totalCount, totalCount - diff));
    // }
    $('#search-result').replaceWith(response);

};
SearchCategory.prototype.appendContent = function (response) {
    if ($('#ProductResultsList ul').length > 0) {
        var content = $(response.view).find('.search-results__list');
        $('#ProductResultsList ul').append(content[0].innerHTML);
        $('#ProductResultsMosaic ul').append(content[1].innerHTML);
    }
    else {
        var typeCategory = this.page.currentCategory.name;
        $('#SearchPage .search-results[data-category=' + typeCategory + '] ul').append($(response.view));
    }

};
SearchCategory.prototype.addFilter = function (filterName, input) {

    this.filters.push({
        text: filterName,
        name: input.name,
        el: input
    });

    this.page.$filtersContainer.append('<li class="clearfix"><button type="button" class="btn btn-link pull-right"><i class="icon-close"></i></button>' + filterName + '</li>');

    this.page.$filtersContainer.parent().show();

    this.currentPage = 0;
    this.fullData = false;
    this.fetchData().then($.proxy(this.replaceContent, this));
};
SearchCategory.prototype.removeFilter = function (filterName) {

    this.page.$filtersContainer.find('li').each(function () {

        if ($(this).text() === filterName) {
            $(this).remove();
            return false;
        }
    });
    for (var i = 0; i < this.filters.length; i++) {

        if (this.filters[i].text === filterName) {
            $(this.filters[i].el).prop('checked', false).closest('.checkbox').removeClass('state--checked');
            this.filters.splice(i, 1);
            break;
        }
    }

    if (!this.page.$filtersContainer.children().length) this.page.$filtersContainer.parent().hide();

    this.currentPage = 0;
    this.fullData = false;
    this.fetchData().then($.proxy(this.replaceContent, this));
};
SearchCategory.prototype.clearFilters = function () {

    this.page.$filtersContainer.find('li').remove();

    for (var i = 0; i < this.filters.length; i++) {
        $(this.filters[i].el).prop('checked', false).closest('.checkbox').removeClass('state--checked');
    }

    this.filters = [];

    this.page.$filtersContainer.parent().hide();

    this.currentPage = 0;
    this.fullData = false;
    this.fetchData().then($.proxy(this.replaceContent, this));
};


$(document).ready(function () {
    var typePage = 'search';
    var $el = $('#SearchPage');
    timeLoop.start();
    if ($el.length == 0) {
        $el = $('#ProductPage');
        typePage = 'product';
    }
    if ($el.length) {

        searchPage = new SearchPage($el, typePage);

        $(window).on('scroll', function () {

            scrollY = $(this).scrollTop();
        });
    }

    var nb = $('.badge').html();
    var $searchProblematicFilters = $('.searchProblematicFilters');
    var $searchResultsItem = $('.search-results__item');

    // $('.accordion-head').on('click',function(){
    //     if($(this).hasClass('collapsed')){
    //         $(this).next('div').find('input[type="checkbox"]')[0].click();
    //     }
    // });

    /* $('#ProductFilters input[type="checkbox"]').on('click',function () {
     var pa = $(this).closest('.checkbox');
     var listCheck = $(this).closest('.collapse').find('.checkbox');
     if(pa.find('input').val() != $(listCheck[0]).find('input').val() && $(listCheck[0]).hasClass('state--checked')){
     $(listCheck[0]).find('input').click();
     }
     if(pa.find('input').val() == $(listCheck[0]).find('input').val()){
     $(listCheck).each(function (index, element) {
     if(index > 0 && $(element).hasClass('state--checked')){
     $(element).find('input').click();
     }
     });
     }
     });*/
    $searchProblematicFilters.on('click', 'input[type="checkbox"]', function () {
        if (!$searchProblematicFilters.hasClass('filtered')) {
            $searchProblematicFilters.addClass('filtered');
        }
        $searchResultsItem.hide();

        var ranges = [];
        $searchProblematicFilters.find('input[name="product_ranges.id[]"]').each(function () {
            if ($(this).prop('checked')) {
                ranges.push($(this).val());
            }
        });

        var i = 0;
        $searchResultsItem.each(function () {
            var item = $(this);

            if ($(this).data('ranges')) {
                var rangesArray = ($(this).data('ranges')).split('|');
                ranges.forEach(function (element, index) {
                    if ($.inArray(ranges[index], rangesArray) != -1) {
                        item.show();
                        i = i + 1;
                    }
                });
            }
            $('.badge').html(i / 2);
        });


        if ($('input:checkbox:checked').length == 0) {
            $('.badge').html(nb);
            $searchResultsItem.show();
            $searchProblematicFilters.removeClass('filtered');
        }
    });

    $('.view-more-docs').click(function () {
        var $this = $(this);
        var $list = $this.prev();
        var page = $this.data('page');
        var url = $this.data('href').replace('__page__', page);
        $.ajax({
            type: 'GET',
            url: url
        }).done(function (response) {
            if (response.view) {
                var view = $(response.view).hide();
                $list.append(view);
                view.slideDown('slow');
                $this.data('page', page + 1);
            }
            if (response.count < 5) {
                $this.remove();
            }
        });
        return false;
    });
});
